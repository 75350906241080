var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"user-params-form"}},[_c('b-form',{staticClass:"float-right",attrs:{"inline":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.loadCadastros.apply(null, arguments)}}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:`input-group-${
          _vm.userParams &&
          _vm.userParams.tipoUsuario &&
          _vm.userParams.tipoUsuario.toString() == '2'
            ? '20'
            : '50'
        }`,attrs:{"aria-label":"Input","placeholder":"Pesquise...","title":`Pesquise inserindo ${
          _vm.userParams &&
          _vm.userParams.tipoUsuario &&
          !['0', '1'].includes(_vm.userParams.tipoUsuario.toString())
            ? 'parte do nome, '
            : ''
        }o CPF ou a MATRÍCULA de um servidor`},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"type":"submit","title":`Pesquise inserindo ${
          _vm.userParams &&
          _vm.userParams.tipoUsuario &&
          !['0', '1'].includes(_vm.userParams.tipoUsuario.toString())
            ? 'parte do nome, '
            : ''
        }o CPF ou a MATRÍCULA de um servidor`}},[_c('i',{staticClass:"fab fa-searchengin"})]),(_vm.userParams.multiCliente > 0 && _vm.dominios.length > 1)?_c('b-form-select',{class:`input-group-flex-${
          _vm.userParams &&
          _vm.userParams.tipoUsuario &&
          _vm.userParams.tipoUsuario.toString() == '2'
            ? '25'
            : '40'
        }`,attrs:{"id":"header-user-dominio","options":_vm.dominios},on:{"change":_vm.save},model:{value:(_vm.userParams.domainCli),callback:function ($$v) {_vm.$set(_vm.userParams, "domainCli", $$v)},expression:"userParams.domainCli"}}):_vm._e(),(_vm.userParams.tipoUsuario >= 1 && _vm.optionYears.length > 1)?_c('b-form-select',{staticClass:"input-group-flex-15",attrs:{"id":"header-user-f_ano","options":_vm.optionYears},on:{"change":_vm.save},model:{value:(_vm.userParams.f_ano),callback:function ($$v) {_vm.$set(_vm.userParams, "f_ano", $$v)},expression:"userParams.f_ano"}}):(_vm.userParams.tipoUsuario >= 2)?_c('span',{staticClass:"form-control singlePayroll input-group-flex-10"},[_vm._v(_vm._s(_vm.userParams.f_ano))]):_vm._e(),(_vm.userParams.tipoUsuario >= 1 && _vm.optionMonths.length > 1)?_c('b-form-select',{staticClass:"input-group-flex-10",attrs:{"id":"header-user-f_mes","options":_vm.optionMonths},on:{"change":_vm.save},model:{value:(_vm.userParams.f_mes),callback:function ($$v) {_vm.$set(_vm.userParams, "f_mes", $$v)},expression:"userParams.f_mes"}}):(_vm.userParams.tipoUsuario >= 2)?_c('span',{staticClass:"form-control singlePayroll input-group-flex-10"},[_vm._v(_vm._s(_vm.userParams.f_mes))]):_vm._e(),(_vm.userParams.tipoUsuario >= 2 && _vm.optionComplementaries.length > 1)?_c('b-form-select',{staticClass:"input-group-flex-10",attrs:{"id":"header-user-f_complementar","options":_vm.optionComplementaries},on:{"change":_vm.save},model:{value:(_vm.userParams.f_complementar),callback:function ($$v) {_vm.$set(_vm.userParams, "f_complementar", $$v)},expression:"userParams.f_complementar"}}):(_vm.userParams.tipoUsuario >= 2)?_c('span',{staticClass:"form-control singlePayroll input-group-flex-10"},[_vm._v(_vm._s(_vm.userParams.f_complementar))]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }