<template>
  <div class="cadas-contratos">
    <b-overlay :show="loading" rounded="sm" @shown="onShown" @hidden="onHidden">
      <div class="client-presentation" v-if="cadastros && cadastros.id">
        <h2>
          {{ `${((userParams.tipoUsuario == 1 || userParams.admin >= 1) || userParams.admin >= 1) ? `Cliente:
          ${cadastros.nome}${userParams.admin >= 1 ? ` (${cadastros.id})`
              :
              ""}` : ""}`
          }}
          <b-button v-if="((userParams.tipoUsuario == 1 || userParams.admin >= 1) || userParams.admin >= 1)"
            :disabled="optionEventos.length == 0 || limiteMargem <= 0.0" pill
            :variant="`outline-${optionEventos.length == 0 || limiteMargem <= 0.0 ? 'danger' : 'success'}`" class="ml-2"
            v-b-tooltip.hover :title="optionEventos.length == 0 || limiteMargem <= 0.0
              ? 'Não há eventos ou margem disponíveis'
              : 'Novo contrato'
              " @click="novoContrato">
            <i class="fa fa-plus"></i>Novo contrato
          </b-button>
        </h2>
        <hr v-if="!form_input_hide" />
      </div>
      <b-alert :show="!contractsCeilTrue" variant="warning">
        Antes de editar um novo contrato para este servidor é necessário que o contrato inválido marcado abaixo seja
        corrijido
      </b-alert>
      <b-form :hidden="form_input_hide">
        <input id="contrato-id" type="hidden" v-model="contrato.id" />
        <b-row>
          <b-col md="12" sm="12">
            <h4>Dados da Consignatária</h4>
            <h6 class="mb-0" v-if="contrato && contrato.user && contrato.user.name">
              Operador: {{ contrato.user.name }}
              <!-- <b-form-rating inline readonly no-border
                v-model="contrato.user.pesoAverbacao" :stars="contrato.user.pesoAverbacao"
                variant="info"></b-form-rating>  -->
            </h6>
            <h6 class="mb-0" v-if="contrato && contrato.user && contrato.user.name">
              Consignatário: {{ contrato.nomeAbrev || contrato.nome }} {{ userParams.admin >= 1 ? `(consignatario
              usuário: ${consignatario.id_consignatario})` : "" }}
              <!-- <b-button variant="outline-primary" @click="mode = 'editConsign'"
                v-if="userParams.admin >= 1 && mode != 'editConsign'">
                <i class="fa fa-pencil"></i>
              </b-button> -->
            </h6>
            <h6 class="mb-1" v-if="this.userParams.openFinance">
              Usuário Autorizado ao Open Finance (Portabilidade de contratos entre bancos): <span
                style="color: #8acf99">Sim&nbsp;<i class="far fa-check-circle"></i></span>
            </h6>
            <h6 class="mb-1 strong">
              Quantidade mínima de quitações antes da renovação:
              {{ contrato.qmar }}
              <i v-if="contrato.parcelasQuitadas >= contrato.qmar" class="far fa-check-circle"
                style="color: #8acf99"></i>
            </h6>
            <h6 class="mb-2" v-if="dataCorte && dataCorte.data_corte">
              Próximo corte deste convênio em:
              <span :class="`strong ${classDCorte}`">{{ dataCorte.data_corte }}</span>
            </h6>
            <hr class="mb-4" />
            <div v-if="['save', 'editContract'].includes(mode)">
              <h4>Dados para o novo Contrato</h4>
              <h6 class="mb-2">
                O primeiro vencimento será no contracheque de:
                <span :class="`strong ${classPVcto}`">{{ dataCorte.primeiro_vencimento }}</span>
              </h6>
              <hr class="mb-4" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col :md="['save', 'editContract'].includes(mode) ? 3 : 2" sm="12">
            <b-form-group :label="`Contrato:${userParams.admin >= 0 && contrato.id ? ` (${contrato.id})` : ''}`"
              label-for="parcela-valor_parcela">
              <b-input-group size="md" class="mb-1">
                <b-form-input id="contrato-contrato" type="text" @keydown="filterKey" v-model="contrato.contrato"
                  :disabled="!['save', 'editContract'].includes(mode)" placeholder="Informe o Número do Contrato..."
                  @blur="getLevenshtein" />
                <b-input-group-append v-if="mode != 'save' && userParams.gestor >= 1">
                  <b-button variant="outline-primary" @click="mode = 'editContract'"
                    v-if="!['save', 'editContract'].includes(mode)">
                    <i class="fa fa-pencil"></i>
                  </b-button>
                  <b-button variant="outline-success" @click="save" v-if="mode == 'editContract'">
                    <i class="fas fa-save"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12" v-if="userParams.gestor >= 1 && ['editPVencim'].includes(mode)">
            <b-form-group label="1º Desconto em: (MM/AAAA)" label-for="contrato-p_vencimento">
              <b-input-group-append>
                <vue-monthly-picker v-model="contrato.primeiro_vencimento" dateFormat="MM/YYYY"
                  :monthLabels="monthLabels" />
                <b-button variant="outline-success" @click="save">
                  <i class="fas fa-save"></i>
                </b-button>
              </b-input-group-append>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12" v-if="userParams.gestor >= 1 && !['save', 'editPVencim'].includes(mode)">
            <b-form-group label="1º Desconto em: (MM/AAAA)" label-for="contrato-p_vencimento">
              <b-input-group-append>
                <span class="form-control disabled text-center">{{
                  lblPrimeiroVcto }}</span>
                <b-button variant="outline-primary" @click="mode = 'editPVencim'">
                  <i class="fa fa-pencil"></i>
                </b-button>
              </b-input-group-append>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12">
            <b-form-group :label="`Parcela:${this.userParams.tipoUsuario != 0 && mode == 'save'
              ? ' (Mx ' + formatter.format(limiteMargem) + ')'
              : ''
              }`" label-for="contrato-valor_parcela">
              <b-input-group prepend="R$">
                <money id="contrato-valor_parcela" type="text" v-model="contrato.valor_parcela"
                  :class="`valor-field-right form-control ${mode == 'save' && contrato.valor_parcela > limiteMargem ? 'is-invalid' : ''}`"
                  :disabled="mode != 'save'" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col :md="['save', 'editContract'].includes(mode) ? 3 : 2" sm="12">
            <b-row>
              <b-col :md="contrato.parcelasQuitadas > 0 ? 7 : 12" sm="12">
                <b-form-group :label="`Parcelas:${this.userParams.tipoUsuario != 0 && mode == 'save'
                  ? ' (Mx ' + consignatario.qmp + ')'
                  : ''
                  }`" label-for="contrato-parcelas">
                  <b-form-input id="contrato-parcelas" type="number" v-model="contrato.parcelas"
                    :disabled="mode != 'save'" min="1" :max="consignatario.qmp" />
                </b-form-group>
              </b-col>
              <b-col md="5" sm="12" v-if="contrato.parcelasQuitadas > 0">
                <b-form-group label="Quitações:" label-for="contrato-parcelas-quitadas">
                  <h4 class="form-control valor-field-center input-disabled">
                    {{ contrato.parcelasQuitadas }}
                  </h4>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col :md="2" sm="12">
            <b-form-group label="Valor Total:" label-for="contrato-valor_total">
              <b-input-group prepend="R$">
                <money id="contrato-valor_total" type="text" v-model="contrato.valor_total" :disabled="mode != 'save'"
                  class="valor-field-right form-control" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col :md="2" sm="12">
            <b-form-group label="Valor Liquido:" label-for="contrato-valor_liquido">
              <b-input-group prepend="R$">
                <money id="contrato-valor_liquido" type="text" v-model="contrato.valor_liquido"
                  :disabled="mode != 'save'" class="valor-field-right form-control" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12" v-if="contrato.data_averbacao">
            <b-form-group label="Averbação:" label-for="contrato-data_averbacao">
              <h4 class="form-control valor-field-center input-disabled">
                {{ contrato.data_averbacao }}
              </h4>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12" v-if="mode == 'editConsign'">
            <b-form-group label="Consignatário:" label-for="contrato-id_consignatario">
              <b-input-group size="md" class="mb-1">
                <b-form-select :options="optionConsigns" v-model="contrato.id_consignatario"
                  id="contrato-id_consignatario"></b-form-select>
                <b-input-group-append>
                  <b-button variant="outline-success" @click="save">
                    <i class="fas fa-save"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2" v-if="contrato.data_liquidacao" sm="12">
            <b-form-group label="Liquidação:" label-for="contrato-data_liquidacao">
              <h4 class="form-control valor-field-right input-disabled">
                {{ contrato.data_liquidacao }}
              </h4>
            </b-form-group>
          </b-col>
        </b-row>
        <b-alert :show="levenshtein" variant="warning">{{ levenshteinLabel }}</b-alert>
        <div id="actions" v-show="!form_input_hide" class="mb-4">
          <b-button variant="primary" v-if="mode == 'save' && canSave" :disabled="(contrato.valor_parcela > limiteMargem &&
            userParams.tipoUsuario != '0' &&
            (!contrato.pesoTotal ||
              (contrato && contrato.pesoTotal && contrato.pesoTotal == STATUS_AVERBAR)))" @click="save"
            class="mr-2">Salvar
          </b-button>
          <b-button variant="outline-danger" v-if="mode === 'remove'" @click="remove" class="mr-2">Excluir</b-button>
          <b-button @click="reset">{{ lblCancelar }}</b-button>
          <!-- Botões de averbação -->
          <span v-if="statusAverbacao.pesoTotal == STATUS_ATIVO || statusAverbacao.cliente"
            class="btn ml-2 disabled btn-info" style="width: 10rem; color:#212529;"><i class="fas fa-user"></i>
            Assinado</span>
          <b-button variant="outline-success"
            v-else-if="contrato.id && (!statusAverbacao.pesoTotal || statusAverbacao.pesoTotal < STATUS_ATIVO)"
            class="ml-2" style="width: 10rem;" @click="who = '0'; oper = '0'; getKeyPass()" href="#dialogConfirm"><i
              class="fas fa-user"></i>
            Cliente</b-button>
          <span
            v-if="statusAverbacao.pesoTotal == STATUS_ATIVO || (statusAverbacao.operador && statusAverbacao.operador.true)"
            class="btn ml-2 disabled btn-info" style="width: 10rem; color:#212529;"><i class="fas fa-check"></i>
            Averbado</span>
          <b-button variant="outline-success"
            v-else-if="contrato.id && (!statusAverbacao.pesoTotal || statusAverbacao.pesoTotal < STATUS_ATIVO)"
            class="ml-2" style="width: 10rem;" @click="who = '1'; oper = '0'; onSubmit('averbar')"
            href="#dialogConfirm"><i class="fas fa-check"></i>
            Operador</b-button>
          <span v-if="statusAverbacao.pesoTotal == STATUS_ATIVO" class="btn ml-2 disabled btn-info"
            style="width: 10rem; color:#212529;"><i class="fas fa-check-double"></i> Deferido</span>
          <b-button variant="outline-success" v-else-if="contrato.id && statusAverbacao.pesoTotal < STATUS_ATIVO"
            :disabled="!(userParams.tipoUsuario == 1 && statusAverbacao.cliente && statusAverbacao.operador && statusAverbacao.operador.true)"
            class="ml-2" style="width: 10rem;" @click="who = '2'; oper = '0'; onSubmit('deferir')"
            href="#dialogConfirm"><i class="fas fa-check-double"></i>
            Deferir</b-button>
          <!-- Fim de botões de averbação -->
          <!-- Botões de liquidação -->
          <b-dropdown class="ml-2" left :variant="`outline-${contrato.parcelasQuitadas >= contrato.qmar ? 'success' : 'danger'
            }`"
            v-if="contrato.id && ['1', '2'].includes(String(userParams.tipoUsuario)) && statusAverbacao.pesoTotal == STATUS_ATIVO && (!statusDesaverbacao.pesoTotal || statusDesaverbacao.pesoTotal == 0)"
            text="Liquidar o contrato" :disabled="busy || !(contrato.parcelasQuitadas >= contrato.qmar)">
            <b-dropdown-item v-for="option in optionStatus" :key="option.id"
              @click="who = '1'; oper = '1'; onSubmit('liquidar', option)">{{
                option.text }}</b-dropdown-item>
          </b-dropdown>
          <b-button variant="outline-danger"
            v-else-if="contrato.id && ['1', '2'].includes(String(userParams.tipoUsuario)) && statusDesaverbacao.pesoTotal && statusDesaverbacao.pesoTotal > 0 && statusDesaverbacao.pesoTotal < (STATUS_LIQUIDADO - STATUS_ATIVO)"
            class="ml-2" @click="who = '2'; oper = '1'; onSubmit('finish')" href="#dialogConfirm"><i
              class="fas fa-check-double"></i>
            Deferir Desaverbação</b-button>
          <span v-else-if="statusDesaverbacao.pesoTotal == (STATUS_LIQUIDADO - STATUS_ATIVO)"
            class="btn ml-2 disabled btn-info" style="width: 10rem; color:#212529;"><i class="fas fa-check-double"></i>
            Desaverbado</span>
          <!-- Fim de botões de liquidação -->
          <b-button variant="outline-success" v-if="((userParams.tipoUsuario == 1 || userParams.admin >= 1) || userParams.admin >= 1) &&
            contrato.pesoTotal == STATUS_AVERBAR &&
            !contrato.data_averbacao &&
            mode != 'remove'
          " class="ml-2" @click="viewContract(contrato)" :disabled="busy"><i class="fas fa-print"></i> Imprimir
            averbação</b-button>
          <b-button variant="outline-success" v-if="userParams.admin >= 2 && statusAverbacao.pesoTotal == STATUS_ATIVO"
            class="ml-2" @click="viewContractJS(contrato)" :disabled="busy"><i class="fas fa-print"></i> Imprimir
            averbação (DEV)
          </b-button>
          <b-row v-if="['1', '2'].includes(String(userParams.tipoUsuario))">
            <b-col :md="statusDesaverbacao.pesoTotal > 0 ? 6 : 12">
              <b-card class="text-center mt-3" v-if="statusAverbacao.pesoTotal">
                <div class="bg-secondary text-light"
                  style="padding-top: 1rem; padding-bottom: 0.5rem; padding-right: 1rem;">
                  <ul style="list-style:none;" v-if="statusAverbacao.pesoTotal < STATUS_ATIVO">O contrato está <strong
                      style="color: rgb(21 25 155);">em processo de averbação</strong>
                    <li v-for="item in averbacoes" :key="item.id">{{ item.tipoUsuario == '0' ? 'Cliente' : 'Operador'
                      }}:
                      {{ item.name }} em {{ item.created_at }}</li>
                  </ul>
                  <ul style="list-style:none;" v-else>A averbação foi deferida por {{ averbacoes.length }} usuário{{
                    averbacoes.length > 1 ?
                      's' : '' }}
                    <li v-for="item in averbacoes" :key="item.id">{{ item.tipoUsuario == '0' ? 'Cliente' : 'Operador'
                      }}:
                      {{ item.name }} em {{ item.created_at }}</li>
                  </ul>
                </div>
              </b-card>
            </b-col>
            <b-col md="6">
              <b-card class="text-center mt-3" v-if="statusDesaverbacao.pesoTotal > 0">
                <div class="bg-secondary text-light"
                  style="padding-top: 1rem; padding-bottom: 0.5rem; padding-right: 1rem;">
                  <ul style="list-style:none;" v-if="contrato.pesoTotal < STATUS_LIQUIDADO">O contrato está <strong
                      style="color: rgb(21 25 155);">em
                      processo de liquidação</strong>
                    <li v-for="item in desaverbacoes" :key="item.id">Operador: {{ item.name }} em {{ item.created_at }}
                    </li>
                  </ul>
                  <ul style="list-style:none;" v-else>A desaverbação foi deferida por {{ desaverbacoes.length }}
                    usuário{{ desaverbacoes.length > 1 ? 's' : '' }}
                    <li v-for="item in desaverbacoes" :key="item.id">Operador: {{ item.name }} em {{ item.created_at }}
                    </li>
                  </ul>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
            <template #overlay>
              <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
                <b-icon icon="cloud-upload" name="cloud1" font-scale="4"></b-icon>
                <div class="mb-3">{{ lblProcessando }}...</div>
                <b-progress min="1" :max="max" :value="counter" variant="success" height="3px" class="mx-n4 rounded-0">
                </b-progress>
              </div>
              <div v-else ref="dialog" id="dialogConfirm" tabindex="-1" role="dialog" aria-modal="false"
                aria-labelledby="form-confirm-label" class="text-center p-3">
                <p>
                  <strong id="form-confirm-label" v-html="labelOverlay"></strong>
                </p>
                <div class="">
                  <b-button block variant="outline-danger" class="mr-3" @click="onCancel">
                    Cancelar
                  </b-button>
                  <b-button block v-if="contrato.pesoTotal <= STATUS_AVERBAR" variant="outline-success"
                    @click="setStatus()" class="mr-3">{{ who == 1 ? 'Averbar Contrato' :
                      'Deferir Averbação' }}</b-button>
                  <b-button block v-if="operEspecial == 'liquidar' && ((userParams.tipoUsuario == 1 || userParams.admin >= 1) || userParams.admin >= 1)
                  " variant="outline-success" @click="finish" class="mr-3">Liquidar</b-button>
                  <b-button block
                    v-if="operEspecial == 'finish' && ((userParams.tipoUsuario == 1 || userParams.admin >= 1) || userParams.admin >= 1)"
                    variant="outline-success" @click="setStatus()" class="mr-3">Deferir Desaverbação</b-button>
                  <!-- <b-button block v-if="operEspecial == 'renovar' && ((userParams.tipoUsuario == 1 || userParams.admin >= 1) || userParams.admin >= 1)
                  " variant="outline-success" @click="renew">Renovar</b-button> -->
                </div>
              </div>
            </template>
          </b-overlay>
        </div>
        <div v-if="userParams.admin >= 2">
          <p>mode: {{ mode }}</p>
          <p>contrato: {{ contrato }}</p>
          <p>averbacoes: {{ averbacoes }}</p>
          <p>desaverbacoes: {{ desaverbacoes }}</p>
          <p>statusAverbacao: {{ statusAverbacao }}</p>
          <p>statusDesaverbacao: {{ statusDesaverbacao }}</p>
          <p>consignatario: {{ consignatario }}</p>
          <p>userParams: {{ userParams }}</p>
        </div>
      </b-form>
      <b-card no-body>
        <div class="cadas-grid">
          <b-table hover striped responsive :items="contratos" :fields="fields" foot-clone>
            <template v-slot:cell(status_label)="data">
              {{ `${data.item.status_label}` }}
            </template>
            <template v-slot:cell(banco)="data">
              {{ `${data.item.banco}` }}
            </template>
            <template #cell(contrato)="data">
              <span v-html="data.item.contrato"></span>
            </template>
            <template v-slot:cell(valor_liquido)="data">
              {{ `R$ ${valueFormater(data.item.valor_liquido)}` }}
            </template>
            <template v-slot:cell(valor_parcela)="data">
              {{ `R$ ${valueFormater(data.item.valor_parcela)}` }}
            </template>
            <template v-slot:cell(parcelas)="row">
              {{ `${row.item.parcelas}` }}
            </template>
            <template v-slot:cell(actions)="row">
              <b-button v-if="userParams.tipoUsuario >= '1' && row.item.pesoTotal <= STATUS_AVERBAR"
                variant="outline-warning" size="sm" @click="loadContrato(row.item, 'save')" class="mr-1"
                v-b-tooltip.hover title="Editar registro`" href="#cadas-panel"><i class="fa fa-pencil"></i></b-button>
              <b-button v-else variant="outline-info" size="sm" @click="loadContrato(row.item, '')" class="mr-1"
                v-b-tooltip.hover title="Ver registro" href="#cadas-panel"><i class="fa fa-eye"></i></b-button>
              <b-button
                v-if="userParams.tipoUsuario >= '1' && row.item.pesoTotal >= STATUS_ATIVO && row.item.pesoTotal < STATUS_LIQUIDADO"
                variant="outline-info" size="sm" @click="viewContract(row.item)" class="mr-1" v-b-tooltip.hover
                title="Detalhes"><i class="fa fa-print"></i></b-button>
              <b-button v-if="userParams.tipoUsuario >= '1' && row.item.pesoTotal <= STATUS_AVERBAR"
                variant="outline-danger" size="sm" @click="loadContrato(row.item, 'remove')" v-b-tooltip.hover
                title="Remover registro" href="#cadas-panel"><i class="fa fa-trash"></i></b-button>
            </template>
          </b-table>
          <b-pagination size="md" v-model="page" :total-rows="count" :per-page="limit" />
        </div>
      </b-card>
      <div>
        <b-modal size="xl" class="modal" id="modalForm" ref="modalForm" hide-footer centered hide-backdrop
          :title="title">
          <b-button @click="$refs.myPdfComponent.print()" variant="outline-success" size="lg" v-b-tooltip.hover
            title="Clique para imprimir"><i class="fas fa-print"></i> Imprimir</b-button>
          <pdf ref="myPdfComponent" :src="fileUrl"></pdf>
        </b-modal>
      </div>
      <template #overlay>
        <div class="text-center">
          <Loading />
        </div>
      </template>
    </b-overlay>
    <div v-if="userParams.admin >= 2">
      <p>this.userParams: {{ userParams }}</p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { dbPrefix, showError } from "@/global";
import { baseApiUrl } from "@/env";
import {
  getDecimalFormater,
  datePickerLocale,
} from "@/config/globalFacilities";
import moment from "moment";
import pdf from "vue-pdf";
import Loading from "@/components/template/Loading";
import VueMonthlyPicker from 'vue-monthly-picker'
import { levenshtein } from "../../global";

export default {
  name: "CadasContratos",
  props: ["userParams", "cadastros"],
  components: { pdf, Loading, VueMonthlyPicker },
  beforeDestroy() {
    this.clearInterval();
  },
  data: function () {
    return {
      STATUS_AVERBAR: 9,
      STATUS_ATIVO: 12,
      STATUS_LIQUIDADO: 20,
      STATUS_CANCELADO: 99,
      levenshtein: false,
      levenshteinLabel: 'false',
      contractsCeilTrue: true,
      sameConsign: false,
      canSave: true,
      fileUrl: undefined,
      labelOverlay: 'Confirma a operação?',
      financeiro: {},
      who: undefined,
      title: "",
      loading: false,
      form_input_hide: true,
      mode: "view",
      keyword: "",
      keyword_res: "",
      labelsDatePicker: datePickerLocale,
      lblPrimeiroVcto: "",
      monthLabels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      contratos: [], // table
      contrato: {}, // field
      statusAverbacao: {}, // field
      statusDesaverbacao: {}, // field
      averbacoes: [], // field
      desaverbacoes: [], // field
      dataCorte: {}, // field
      classDCorte: '', // Se a data d ecorte for passada, define text-
      classPVcto: '', // Se a data d ecorte for passada, define text-
      limiteMargem: undefined,
      somaContratos: 0,
      consignatario: {}, // field
      optionEventos: [],
      lblCancelar: "Cancelar",
      lblProcessando: "Liquidando",
      valueFormater: getDecimalFormater,
      busy: false,
      processing: false,
      counter: 1,
      pluralize: "",
      operEspecial: "",
      max: 20,
      interval: null,
      userKeyPass: undefined,
      formatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      meioAverbacao: [
        { value: "-1", text: "Selecione" },
        { value: "0", text: "On line" },
        { value: "1", text: "Presencial" },
      ],
      lang: {
        formatLocale: {
          firstDayOfWeek: 0,
        },
        monthBeforeYear: true,
      },
      // optionRenew: { value: "2", text: "Renovação de Contrato" },
      optionStatusDefault: [
        { value: "2", text: "Liquidação Total/Renovação de Contrato" },
        { value: "6", text: "Servidor Aposentado (Para outro convênio)" },
        { value: "104", text: "Servidor Falecido" },
        { value: "105", text: "Servidor Exonerado" },
      ],
      optionStatus: [],
      optionConsigns: [
        { value: "1", text: "Banco do Brasil" },
        { value: "2", text: "Caixa Econômica" },
      ],
      optionStatusSelected: {},
      page: 1,
      limit: 0,
      count: 0,
      fields: [
        {
          key: "id",
          label: "Código",
          sortable: true,
          tdClass: "d-none",
          thClass: "d-none",
        },
        {
          key: "status_label",
          label: "Situação",
          sortable: true,
          tdClass: (type) => {
            //, key, item
            // TODO: se type inicia com a palavra "Averbar" então bg-warning text-white
            if (type == "Averbar") return "bg-warning text-white";
          },
        },
        { key: "banco", label: "Consignatário", sortable: true },
        // { key: "id_con_eventos", label: "Evento em folha", sortable: true },
        { key: "parcelas", label: "Parcelas", sortable: true, tdClass: "valor-field-center", thClass: "valor-field-center" },
        { key: "contrato", label: "Contrato", sortable: true },
        {
          key: "valor_parcela",
          label: "Valor parcela",
          sortable: true,
          tdClass: "valor-field-right",
        },
        {
          key: "valor_liquido",
          label: "Valor liquido",
          sortable: true,
          tdClass: "valor-field-right",
        },
        {
          key: "actions",
          label: "Ações",
          thClass: "col-actions2",
          tdClass: "col-actions2",
        },
      ],
    };
  },
  methods: {
    async getCeil(idCadServidor) {
      const url = `${baseApiUrl}/contratos/f-a/gcl`;
      const ceil = await axios.post(url, { id_cad_servidores: idCadServidor })
      this.contractsCeilTrue = true
      if (ceil.data.contrato) this.contractsCeilTrue = false
      return ceil
    },
    getLabel(array, key) {
      const item = array.filter((it) => it.value == key);
      return item && item[0] && item[0].text ? item[0].text : "";
    },
    getLevenshtein() {
      this.levenshtein = false
      this.contratos.every(element => {
        element.contrato = this.extractContent(element.contrato)
        const percent = 94.7
        if (levenshtein(element.contrato, this.contrato.contrato) > percent) {
          if (element.pesoTotal >= this.STATUS_ATIVO && element.pesoTotal < this.STATUS_LIQUIDADO) {
            element.contrato = this.highlight(element.contrato, element.contrato)
            this.levenshtein = true
            let equality = 'muito parecido com algum'
            if (this.extractContent(element.contrato) == this.contrato.contrato) {
              equality = 'igual a um'
              this.canSave = false
            }
            this.levenshteinLabel = `O contrato informado (${this.contrato.contrato}) é ${equality} contrato ativo. Verifique antes de continuar.`
            return false
          }
        }
        this.canSave = true
      });
    },
    novoContrato(oldContrato = undefined) {
      if (!this.contractsCeilTrue) this.form_input_hide = true;
      if (this.contractsCeilTrue) {
        this.mode = "save";
        this.loadConsignatario(this.userParams.consignatario);
        this.contrato = {
          id_consignatario: this.consignatario.id_consignatario,
          id_user: this.userParams.id,
          id_cad_servidores: this.cadastros.id,
          qmar: this.consignatario.qmar,
        };
        if (oldContrato) {
          this.contrato.contrato = oldContrato.contrato;
          this.contrato.id_con_eventos = oldContrato.id_con_eventos;
        }
        if (this.contrato && this.contrato.id)
          this.contrato.complementar = this.getParcelasQuitadas(this.contrato);
        this.contrato.parcelas = this.consignatario.qmp;
        this.loadEventos(this.userParams.consignatario, null);
        this.contrato.id_con_eventos = this.optionEventos[0].value;
        this.form_input_hide = false;
      }
    },
    async loadContratos() {
      this.loading = !this.loading;
      const consignatario =
        ((this.userParams.tipoUsuario == 1 || this.userParams.admin >= 1) || this.userParams.admin >= 1)
          ? `&idConsignatario=${this.userParams.consignatario}`
          : "";
      const url = `${baseApiUrl}/contratos-serv/${this.cadastros.id}?page=${this.page}&key=${this.keyword}&cpf=${this.user.cpf}${consignatario}`;
      axios.get(url).then(async (res) => {
        this.contratos = res.data.data;
        this.contratos.forEach(element => {
          element.status_label = "Averbar"
          if (element.pesoTotal >= this.STATUS_ATIVO && element.pesoTotal < this.STATUS_LIQUIDADO) element.status_label = "Ativo"
          else if (element.pesoTotal >= this.STATUS_LIQUIDADO && element.pesoTotal < this.STATUS_CANCELADO) element.status_label = "Liquidado"
          else if (element.pesoTotal == this.STATUS_CANCELADO) element.status_label = "Cancelado"
          if (this.userParams.admin >= 2) element.contrato += ` (${element.id})`
        });
        const ceil = await this.getCeil(this.cadastros.id)
        if (ceil.data.contrato) {
          this.contratos.forEach(element => {
            if (element.contrato == ceil.data.contrato) {
              element.contrato = this.highlight(element.contrato, element.contrato)
            }
          });
        }
        this.$emit("somaContratos", this.getSomaContratos());
        this.count = res.data.count;
        this.limit = res.data.limit;
        this.pluralize = this.count > 1 ? "s" : "";
        this.keyword_res = `${this.count} resultado${this.pluralize}`;
        this.loading = !this.loading;
      });
    },
    async loadDataCorte() {
      const dataCorte = await axios.post(`${baseApiUrl}/contratos/f-a/gdc`)
      const dataPV = moment(dataCorte.data.primeiro_vencimento)
      this.dataCorte.primeiro_vencimento = dataPV.format('DD/MM/YYYY')
      const dataCt = moment(dataCorte.data.data_corte)
      this.dataCorte.data_corte = dataCt.format('DD/MM/YYYY')
      if (dataCt.month() === moment().month()) this.classDCorte = ' text-danger'
      else this.classDCorte = ' text-success'
      if (dataPV.month() === moment().month()) this.classPVcto = ' text-danger'
      else this.classPVcto = ' text-success'
      dataCorte.data.data_corte
    },
    async loadContrato(contrato, mode = "view") {
      this.loading = !this.loading;
      const url = `${baseApiUrl}/contratos/${contrato.id}`;
      axios.get(url).then(async (res) => {
        this.contrato = res.data.data;
        await this.loadStatus()
        if (this.userParams.consignatario) this.sameConsign = this.contrato.id_consignatario == this.userParams.consignatario
        /*
        Pode liquidar o contrato caso:
        1: O operador seja do mesmo banco que contratou ou tenha permissõa para open finance
        2: O usuário tenha permissão 4 ou superior para contratos
        3: O contrato esteja ativo
        4: O contrato não esteja liquidado
        */
        this.contrato.user = res.data.user;
        this.contrato.u_keypass = this.cadastros.password;
        this.contrato.id_cadas = this.cadastros.id_cadas;
        this.lblPrimeiroVcto = moment(contrato.primeiro_vencimento).format("MM/YYYY")
        if (this.contrato.id_consignatario && this.userParams.consignatario)
          this.loadConsignatario(this.contrato.id_consignatario);
        this.optionStatus = this.optionStatusDefault
        if (this.userParams.openFinance && this.userParams.consignatario != this.contrato.id_consignatario) this.optionStatus = [{ value: "900", text: "Open Finance" }];
        if (this.userParams.tipoUsuario == '0') {
          this.lblCancelar = "Fechar";
          this.mode = null;
        } else {
          this.mode = mode;
          this.loadEventos(
            this.contrato.id_consignatario,
            this.contrato.id_con_eventos
          );
        }
        if (this.contrato.data_averbacao != null)
          this.contrato.data_averbacao = moment(
            this.contrato.data_averbacao
          ).format("DD-MM-YYYY");
        if (this.contrato.data_liquidacao != null)
          this.contrato.data_liquidacao = moment(
            this.contrato.data_liquidacao
          ).format("DD-MM-YYYY");
        this.getParcelasQuitadas(this.contrato);
        await this.getMargem()
        this.form_input_hide = false;
        this.loading = !this.loading;
      });
    },
    async loadStatus(idContrato) {
      this.loading = !this.loading;
      const url = `${baseApiUrl}/contratos-status/f-a/get?id_contrato=${idContrato || this.contrato.id}`;
      axios.post(url).then(async (res) => {
        const contratoStatusAverbacao = this.averbacoes = res.data.averbacao;
        const contratoStatusDesaverbacao = this.desaverbacoes = res.data.desaverbacao;
        this.statusAverbacao = {}
        this.statusDesaverbacao = {}
        if (contratoStatusAverbacao.length > 0) {
          this.statusAverbacao.pesoTotal = 0
          contratoStatusAverbacao.forEach(element => {
            if (element.tipoUsuario == '0') this.statusAverbacao.cliente = true
            else if (['1', '2'].includes(String(element.tipoUsuario))) this.statusAverbacao.operador = { true: true, id_user: element.uid }
            this.statusAverbacao.pesoTotal += element.peso
            element.created_at = moment(element.created_at).format('DD/MM/YYYY HH:mm:ss');

          });
        }
        if (contratoStatusDesaverbacao.length > 0) {
          this.statusDesaverbacao.pesoTotal = 0
          contratoStatusDesaverbacao.forEach(element => {
            if (element.tipoUsuario == '0') this.statusDesaverbacao.cliente = true
            else if (['1', '2'].includes(String(element.tipoUsuario))) this.statusDesaverbacao.operador = { true: true, id_user: element.uid }
            this.statusDesaverbacao.pesoTotal += element.peso
            element.created_at = moment(element.created_at).format('DD/MM/YYYY HH:mm:ss');

          });
        }
        // Caso this.statusContrato.operador == true e this.statusContrato.operador.id_user != this.userParams.id então this.statusContrato.userPodeDeferir = true
        // this.statusContrato.userPodeDeferir = this.statusAverbacao && this.statusAverbacao.cliente && this.statusAverbacao && this.statusAverbacao.operador
        this.loading = !this.loading;
      });
    },
    async setStatus(bodyTo) {
      if (!bodyTo)
        bodyTo = {
          id_con_contratos: this.contrato.id,
          who: String(this.who) || '2',
        }
      bodyTo = { ...bodyTo, oper: String(this.oper) }
      const url = `${baseApiUrl}/contratos-status/f-a/set`;
      axios.post(url, bodyTo).then(async (res) => {
        if (res.data.id) {
          await this.loadContrato({ id: this.contrato.id })
          let msgs = ''
          if (this.oper == '0')
            switch (bodyTo.who) {
              case '0': msgs = 'Parabéns! Contrato assinado pelo cliente'; break;
              case '1': msgs = 'Parabéns! Contrato assinado pelo operador'; break;
              case '2': msgs = 'Parabéns! Contrato deferido!'; break;
              default: msgs = 'Tipo de operaçãode averbação não definida...'; break
            }
          else if (this.oper == '1')
            switch (bodyTo.who) {
              case '1': msgs = 'Parabéns! Desaverbação assinada pelo operador'; break;
              case '2': msgs = 'Parabéns! Desaverbação deferida e limite liberado!'; break;
              default: msgs = 'Tipo de operação de desaverbação não definida...'; break
            }
          this.$toasted.global.defaultSuccess({ msg: msgs });
          this.loadContratos();
        } else {
          this.$toasted.global.defaultWarning({ msg: res.data });
        }
        this.busy = this.processing = false;
      })
        .catch(error => {
          this.$toasted.global.defaultError({ msg: error });
          this.busy = this.processing = false;
        });
    },
    async getMargem() {
      this.loading = !this.loading;
      const url = `${baseApiUrl}/contratos/f-a/gmc`;
      await axios.post(url, { id_cad_servidores: this.cadastros.id }).then((res) => {
        this.limiteMargem = res.data.margemFinal;
        this.loading = !this.loading;
      });
    },
    extractContent(s) {
      var span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    highlight(value, markTxt) {
      let target = value;
      let targetMark = markTxt
        .toString()
        .trim()
        .replace(/\s\s+/g, " ")
        .split(" ");
      targetMark.forEach((elementMark) => {
        target = target.replaceAll(
          elementMark,
          `<mark class="foundMark">${elementMark}</mark>`
        );
      });
      return target;
    },
    viewContractJS(contrato) {
      this.viewContract(contrato);
    },
    viewContract(contrato) {
      this.loading = !this.loading;
      this.title = `Contrato de consignação ${contrato.contrato}`;
      const idConContratos = contrato.id;
      const fileName = contrato.contrato;
      const dbSchema = `${dbPrefix}_${this.userParams.cliente}_${this.userParams.dominio}`;
      const url = `${baseApiUrl}/contratos/f/gavb?idConContratos=${idConContratos}&dbSchema=${dbSchema}&fileName=${fileName}`;
      this.fileUrl = url;
      this.showModal();
      this.loading = !this.loading;
    },
    hideModal() {
      this.$refs.modalForm.hide();
    },
    showModal() {
      this.$refs.modalForm.show();
    },
    save() {
      const method = this.contrato.id ? "put" : "post";
      const id = this.contrato.id ? `/${this.contrato.id}` : "";
      this.contrato.primeiro_vencimento = moment(this.contrato.primeiro_vencimento).endOf('month').format('YYYY-MM-DD')
      const objPost = this.contrato
      if (['editPVencim', 'editContract'].includes(this.mode)) {
        delete objPost.u_keypass
        this.mode = 'view'
      }
      axios[method](`${baseApiUrl}/contratos${id}`, objPost)
        .then((res) => {
          this.$toasted.global.defaultSuccess();
          this.lblPrimeiroVcto = moment(res.data.primeiro_vencimento).format("MM/YYYY")
          this.loadContrato(res.data);
          // this.loadStatus();
          // this.getMargem();
          this.getRefresh();
        })
        .catch(showError);
    },
    async getKeyPass() {
      let retVal = undefined;
      let idCadas = undefined
      let idUser = undefined
      if (this.cadastros && this.cadastros.cpf) {
        let cadastro = await axios.get(`${baseApiUrl}/users-cpf/${this.cadastros.cpf}`)
        idCadas = cadastro.data.id_cadas
        idUser = cadastro.data.id
      }
      if (!idCadas) {
        showError(
          "O cliente ainda não tem perfil de usuário. Não é possível averbar!"
        );
        setTimeout(() => {
          showError("Por favor solicite ao cliente que crie seu perfil");
        }, 1000);
        this.clearInterval();
      } else {
        retVal = prompt(
          `Prezad${this.cadastros.sexo == "0" ? "o" : "a"} ${this.cadastros.nome}, para sua segurança confirme abaixo sua senha de usuári${this.cadastros.sexo == "0" ? "o" : "a"}`,
          "Digite aqui a sua senha:"
        );
        const body = {
          id_con_contratos: this.contrato.id,
          u_keypass: retVal,
          id_cadas: idCadas,
          id_users: idUser,
          who: '0',
          oper: '0'
        }
        if (retVal) this.setStatus(body);
        else showError("Senha do cliente não informada");
      }
      // } else if (this.userParams.pesoAverbacao == this.STATUS_ATIVO) {
      //   this.averbation();
      // }
    },
    finish() {
      // Dados para setar o status do contrato na tabela con_contr_status
      const body = {
        id_con_contratos: this.contrato.id,
        who: this.who,
        oper: '1'
      }
      this.setStatus(body);
      // Setar novo status do contrato e das parcelas nas tabelas con_contratos e con_parcelas e 'liquidar' o contrato
      this.contrato.status = 20;
      this.contrato.parcelasSituacao = this.optionStatusSelected.value;
      this.contrato.data_liquidacao = moment();
      axios
        .put(`${baseApiUrl}/contratos/${this.contrato.id}`, this.contrato)
        .then((res) => {
          console.log(res.data);
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);

    },
    loadConsignatario(id) {
      const url = `${baseApiUrl}/consignatarios/${id}`;
      axios.get(url).then((res) => {
        this.consignatario = res.data.data;
      });
    },
    getSomaContratos() {
      const body = { id: this.cadastros.id };
      const url = `${baseApiUrl}/contratos/f-a/gsc`;
      axios.post(url, body).then((res) => {
        this.somaContratos = res.data.data.soma_parcelas;
      });
      return this.somaContratos;
    },
    getParcelasQuitadas(contrato) {
      const body = { id_con_contratos: contrato.id };
      const url = `${baseApiUrl}/contratos/f-a/gpq`;
      axios.post(url, body).then((res) => {
        this.parcelasQuitadas = res.data.data.parcelasQuitadas;
      });
      return this.parcelasQuitadas;
    },
    remove() {
      const id = this.contrato.id;
      const url = `${baseApiUrl}/contratos/${id}`
      axios
        .delete(url)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.reset();
        })
        .catch(showError);
    },
    loadEventos(id_consignatario, id_con_eventos) {
      const url = `${baseApiUrl}/con-eventos/rubricas-consignatario/${id_consignatario}?id_cad_servidores=${this.cadastros.id}&id_con_eventos=${id_con_eventos}`;
      axios.get(url).then((res) => {
        this.optionEventos = res.data.data.map((data) => {
          const textLabel = `${data.id_evento} - ${data.evento_nome}`;
          return { value: data.id, text: textLabel };
        });
      });
    },
    getRefresh() {
      this.keyword = "";
      this.page = 1;
      this.loadContratos();
      this.pdfSrc = undefined;
      if (this.userParams.consignatario) {
        this.loadConsignatario(this.userParams.consignatario);
        this.loadEventos(
          this.userParams.consignatario,
          this.contrato.id_con_eventos ? this.contrato.id_con_eventos : null
        );
        this.loadDataCorte();
      }
      this.getSomaContratos();
    },
    reset() {
      this.keyword = "";
      this.page = 1;
      this.limit = 0;
      this.count = 0;
      this.contrato = {};
      this.form_input_hide = true;
      this.getRefresh();
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    onShown() {
      if (this.$refs.dialog) this.$refs.dialog.focus();
    },
    onHidden() {
      // this.$refs.submit.focus();
    },
    onSubmit(operEspecial, optionStatusSelected) {
      if (operEspecial == 'deferir' && this.statusAverbacao.operador.id_user == this.userParams.id) {
        //Seu usuário já averbou o contrato. Por favor solicite que outro defira a averbação
        this.$toasted.global.defaultWarning({ msg: 'Você não pode deferir a averbação de um contrato que você mesmo criou ou averbou' });
        return
      }
      if (operEspecial == 'finish' && this.statusDesaverbacao.operador.id_user == this.userParams.id) {
        //Seu usuário já averbou o contrato. Por favor solicite que outro defira a averbação
        this.$toasted.global.defaultWarning({ msg: 'Você não pode deferir a desaverbação de um contrato que você mesmo iniciou a desaverbou' });
        return
      }
      if (optionStatusSelected) {
        this.optionStatusSelected = optionStatusSelected
        this.labelOverlay = `Confirma a liquidação pelo motivo<br>${this.optionStatusSelected.text}?`
        if (this.optionStatusSelected.value == '900') {
          this.labelOverlay += `<br/><br/>O Open Finance já foi efetivado<br/>na instituição anterior?`
          this.labelOverlay += `<br/><br/>O registro atual entrará em processo de liquidação e você deverá<br/>criar um novo para sua instituição`
        } else {
          this.labelOverlay += `<br/><br/>O registro atual entrará em processo de liquidação`
          this.labelOverlay += `<br/><br/>A liquidação deverá ser deferida por dois usuários<br/>diferentes`
        }
      }
      this.operEspecial = operEspecial;
      this.processing = false;
      this.busy = true;
    },
    onCancel() {
      this.busy = false;
    },
    filterKey(e) {
      const key = e.key;
      if ([".", "-", "/", "+", ","].includes(key)) {
        showError("Digite apenas números");
        return e.preventDefault();
      }
    },
  },
  mounted() {
    this.loadContratos();
    setTimeout(() => {
      if (this.userParams.consignatario) {
        this.loadConsignatario(this.userParams.consignatario);
        this.loadEventos(this.userParams.consignatario, null);
        this.loadDataCorte()
      }
    }, 1000);
    this.getSomaContratos();
    this.getMargem()
  },
  watch: {
    page() {
      this.loadContratos();
    },
  },
  computed: mapState(["user"]),
};
</script>

<style scoped>
.float-right {
  margin-top: 5px;
  padding: 1px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.disabled {
  background: #e9ecef;
}

.modal {
  height: 95vh;
}

.modal-body {
  height: 100vh;
  /* overflow-y: auto; */
  overflow-y: scroll !important;
}

.client-presentation {
  padding: 3px;
}

.input-disabled {
  background-color: #e9ecef;
}

.strong {
  font-weight: bold;
}

p {
  margin-bottom: 0;
}
</style>
