<template>
  <div class="cons-liquidations">
    <b-form>
      <b-overlay :show="loading" rounded="sm" @shown="onShown" @hidden="onHidden">
        <input id="contrato-id" type="hidden" v-model="contrato.id" />
        <h2>
          Utilize este formulário para inserir um lote de liquidações conforme o leiaute abaixo
        </h2>
        <b-row>
          <b-col>
            <div id="actions" class="mb-3 mt-2">
              <b-button variant="primary" :disabled="!contrato.lines || loading" @click="save()"
                :class="`mr-2`">Salvar</b-button>
              <b-button variant="warning" @click="getValidation()" :class="`mr-2`" :disabled="loading">Ver
                Validação</b-button>
              <b-button variant="success" @click="setNewValidation()" :class="`mr-2`" :disabled="loading">Nova
                Validação</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8" sm="4">
            <b-form-group :label="`Consignatário (Banco)${consignatario && Number(consignatario.unConv) === 1 ? ' Convênio único' : ''}`" label-for="contrato-id_consignatario">
              <b-form-select id="contrato-id_consignatario" :options="optionConsigs" v-model="contrato.id_consignatario"
                :disabled="loading" placeholder="Informe o consignatário..." @change="getUnCon()" />
            </b-form-group>
          </b-col>
          <b-col md="2" sm="4">
            <b-form-group label="Exercício de operação" label-for="contrato-exercicio">
              <b-form-select id="contrato-exercicio" :options="optionExercicios" v-model="contrato.exercicio"
                :disabled="loading" placeholder="Informe o exercício..." />
            </b-form-group>
          </b-col>
          <b-col md="2" sm="2">
            <b-form-group label="Versão" label-for="contrato-versao">
              <b-form-input type="number" id="contrato-versao" placeholder="Versão..." title="Versão" v-b-tooltip.hover
                v-model="contrato.versao" ref="versao" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!validationResult">
          <b-col>
            <b-form-group label="Lote de liquidações de convenentes" label-for="contrato-lines">
              <h5 class="bold">
                !!! Importante: Não informar cabeçalhos de colunas
              </h5>
              <h6>
                Nome;Matrícula;Nr.operação;Seq.oper.;CPF;Valor parcela;Valor consignado
              </h6>
              <b-form-textarea id="contrato-lines" v-model="contrato.lines"
                placeholder="Cole aqui as linhas contendo os dados conforme o leiaute acima e as colunas separadas por ';'(ponto e vírgula)."
                rows="10"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="validationResult">
          <span style="font-size: 1.25rem;">Resultado das validações</span><br><span style="color: red;">Após corrigir
            tudo, se necessário importe novos dados</span>
          <b-row>
            <b-col md="4" v-for="item in validationResult" :key="item">
              <span v-html="item" />
            </b-col>
          </b-row>
        </div>
        <template #overlay>
          <div class="text-center">
            <Loading />
            <p id="cancel-label">
              Você pode continuar esperando ou pode clicar abaixo e até sair da
              página. Só não pode fechar o navegador. Ok? &#128521;
            </p>
            <p id="cancel-label">
              A importação ocorrerá mesmo que esta aba seja fechada
            </p>
            <h3>Por favor aguarde. Isso pode levar vários minutos...</h3>
            <b-button ref="cancel" variant="outline-danger" size="sm" aria-describedby="cancel-label"
              @click="loading = false">
              Não quero esperar
            </b-button>
          </div>
        </template>
      </b-overlay>
    </b-form>
    <p v-if="userParams.admin">contrato: {{ contrato }}</p>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import { showError } from "@/global";
import { baseApiUrl } from "@/env";
import { capitalizeFirst } from "@/config/globalFacilities";
import Loading from "@/components/template/Loading";

export default {
  name: "ContrValidations",
  components: { Loading },
  data: function () {
    return {
      userParams: {},
      validationResult: undefined,
      itemModal: {
        id: "item-modal",
        title: "",
        content: "",
      },
      loading: false,
      contrato: {
        versao: 1
      }, // field
      optionConsigs: [],
      optionExercicios: [],
      consignatario: {},
    };
  },
  methods: {
    setExercicios() {
      const optionExercicios = [];
      const startDate = new Date(2024, 6); // Julho de 2024 (mês é 0-indexado)
      const endDate = new Date(); // Mês atual

      let currentDate = startDate;

      while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adiciona zero à esquerda se necessário

        const value = `${year}${month}`;
        const text = `${month}/${year}`;

        optionExercicios.push({ value, text });

        // Incrementa para o próximo mês
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      this.optionExercicios = optionExercicios;
    },
    async getConsignees() {
      this.optionConsigs = [];
      const url = `${baseApiUrl}/consignatarios/f/gcgn`;
      await axios.get(url).then((res) => {
        this.optionConsigs = res.data.data.map((data) => {
          return { value: data.id_consignatario, text: data.nome + ": " + data.agencia, unConv: data.unico_municipio };
        });
        // Setar como default a primeira opção
        // this.contrato.id_consignatario = this.optionConsigs[0].value;        
        if (this.userParams && this.userParams.consignatario) {
          this.contrato.id_consignatario = this.userParams.consignatario;
          this.getUnCon();
        }
      });
    },
    // Marque consignatario de acordo com a opção selecionada em optionConsigs
    getUnCon() {
      const consignatario = this.optionConsigs.find((consig) => consig.value === this.contrato.id_consignatario);
      this.consignatario = consignatario;
    },
    setNewValidation() {
      this.contrato.versao = 1;
      this.loading = false;
      this.validationResult = undefined
    },
    getValidation(unConv = false) {
      unConv = (this.consignatario.unConv && Number(this.consignatario.unConv) === 1) ? true : false;
      const url = `${baseApiUrl}/con-validacoes${unConv ? '-un-conv' : ''}/f-a/gcp`;
      const obj = { ...this.contrato };
      axios.post(url, obj).then((res) => {
        this.validationResult = []
        const validations = res.data.inconsistencias
        validations.forEach((validation) => {
          this.validationResult.push(this.formatarDadosParaHTML(validation));
        });
      });
    },
    formatarDadosParaHTML(dados) {
      let htmlString = '<p style="border: groove; border-radius: 5px; padding: 5px 0px 5px 5px;">';

      for (const key in dados) {
        if (Object.prototype.hasOwnProperty.call(dados, key)) {
          const value = dados[key];

          // Verifica se o valor é um objeto
          if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            htmlString += `<strong>${capitalizeFirst(key.replaceAll('_', ' '))}:</strong><br />`;

            // Adiciona indentação para os detalhes
            for (const subKey in value) {
              if (Object.prototype.hasOwnProperty.call(value, subKey)) {
                const subValue = value[subKey];
                htmlString += `<span style="margin-left: 20px;"><strong>${capitalizeFirst(subKey.replaceAll('_', ' '))}:</strong> ${subValue}</span><br />`;
              }
            }
          } else if (Array.isArray(value)) {
            htmlString += `<strong>${capitalizeFirst(key.replaceAll('_', ' '))}:</strong>`;
            htmlString += '<ul>';
            value.forEach((item) => {
              htmlString += `<li>${item.text}</li>`;
            });
            htmlString += '</ul>';
          } else {
            htmlString += `<strong>${capitalizeFirst(key.replaceAll('_', ' '))}:</strong> ${value}<br />`;
          }
        }
      }

      htmlString += '</p>';
      return htmlString;
    },
    save(unConv = false) {      
      unConv = (this.consignatario.unConv && Number(this.consignatario.unConv) === 1) ? true : false;
      const url = `${baseApiUrl}/con-validacoes${unConv ? '-un-conv' : ''}`
      this.isValid = false;
      this.loading = !this.loading;
      const obj = { ...this.contrato };
      axios.post(url, obj)
        .then((res) => {
          this.$toasted.global.defaultSuccess({ msg: res.data.data });
          this.isValid = res.data.isValid || false;
          this.loading = !this.loading;
          this.validationResult = []
          const validations = res.data.inconsistencias
          validations.forEach((validation) => {
            this.validationResult.push(this.formatarDadosParaHTML(validation));
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = !this.loading;
          showError(err);
        });
    },
    downloadFiles(unConv = false) {      
      unConv = (this.consignatario.unConv && Number(this.consignatario.unConv) === 1) ? true : false;
      const url = `${baseApiUrl}/con-validacoes${unConv ? '-un-conv' : ''}/f/gimf`
      axios.get(url).then((res) => {
        const data = JSON.stringify(res.data);
        const element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," +
          encodeURIComponent(
            data
              .replaceAll('","', "\n")
              .replaceAll('","', "\n")
              .replaceAll('"', "")
              .replaceAll("},", "}" + "\n")
              .replaceAll(",{", "\n" + "{")
              .replaceAll("[", "")
              .replaceAll("]", "")
          )
        );
        element.setAttribute("download", "filename.txt");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
    },
    onShown() {
      if (this.$refs.cancel) this.$refs.cancel.focus();
    },
    onHidden() { },
    async loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      await axios.get(url).then((res) => {
        this.userParams = res.data;
        this.userParams.domainCli = `${this.userParams.cliente}_${this.userParams.dominio}`;

        this.contrato.exercicio = this.userParams.f_ano + this.userParams.f_mes;
        this.contrato.id_consignatario = this.userParams.id_consignatario;
      });
    },
  },
  async mounted() {
    this.setExercicios();
    await this.loadUserParams();
    await this.getConsignees();
  },
  computed: mapState(["user"]),
};
</script>

<style scoped></style>