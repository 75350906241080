<template>
  <b-modal v-model="showModal" size="lg" centered hide-backdrop class="modal"
    title="Aviso Importante: Conformidade com a LGPD">
    <p>Prezados Operadores,</p>
    <p>
      Compreendemos que as recentes mudanças implementadas em conformidade com a LGPD (Lei Geral de Proteção de Dados)
      têm gerado alguns desafios no nosso fluxo de trabalho, especialmente no que se refere à impossibilidade de
      averbação sem a senha do cliente. Essas mudanças são essenciais para garantir a segurança e a privacidade dos
      dados dos nossos clientes, conforme exigido por lei.
    </p>
    <p>
      Sabemos que estas novas exigências podem ser frustrantes e agradecemos pela paciência e compreensão de todos.
      Estamos comprometidos em oferecer suporte e buscar soluções que facilitem nosso trabalho diário dentro dos
      parâmetros legais.
    </p>
    <p>
      Para <strong>ajudar a lidar com a demanda e garantir que possamos prestar o melhor serviço possível</strong>,
      solicitamos que todos os operadores instruam os clientes a procurar o RH dos seus respectivos municípios para
      atualizar seus dados pessoais, <strong>principalmente e-mail e telefone</strong>. Essas atualizações são cruciais
      para a continuidade e eficiência dos nossos serviços.
    </p>
    <p>
      <strong>Passos a serem seguidos:</strong>
    <ul>
      <li><strong>Instruir o Cliente:</strong> Informe ao cliente sobre a necessidade de atualizar seus dados pessoais
        com o RH do município.</li>
      <li><strong>Registrar a Solicitação:</strong> Documente o atendimento e a orientação dada ao cliente para futuras
        referências.</li>
    </ul>
    </p>
    <p>
      Agradecemos imensamente a colaboração de todos e estamos disponíveis para esclarecer quaisquer dúvidas.
    </p>
    <p>Atenciosamente,<br>Mega Assessoria e Tecnologia LTDA</p>
    <p>Fonte e referência: <a href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm"
        v-b-tooltip.hover title="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm"
        target="_blank">LGPD</a></p>
    <template #modal-footer="{ ok }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="success" @click="ok">
        Fechar
      </b-button>
      <b-button size="sm" variant="danger" @click="doNotShowAgain()">
        Não mostrar mais
      </b-button>
    </template>
  </b-modal>

</template>

<script>
export default {
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      if (!this.getCookie("lgpdModalSuporteDismiss")) {
        this.showModal = true;
      }
    },
    doNotShowAgain() {
      this.setCookie("lgpdModalSuporteDismiss", "true", 3650);
      this.closeModal();
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  },
  mounted() {
    this.openModal();
  }
};
</script>

<style>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
