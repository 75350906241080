var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title"},[_c('b-row',[_c('b-col',{attrs:{"md":['/'].includes(_vm.$route.path) ? 4 : 12}},[_c('h1',[(_vm.icon)?_c('i',{class:_vm.icon}):_vm._e(),_vm._v(" "+_vm._s(_vm.main))]),_c('h2',[_vm._v(_vm._s(_vm.sub))])]),(_vm.userParams && _vm.userParams.id && ['/'].includes(_vm.$route.path))?_c('b-col',{attrs:{"md":(_vm.userParams.tipoUsuario == 1 || _vm.userParams.admin >= 1) ? '5' : '7',"offset-md":(_vm.userParams.tipoUsuario == 1 || _vm.userParams.admin >= 1) ? '3' : '1'}},[(
          _vm.userParams &&
          _vm.userParams.id &&
          (_vm.userParams.admin >= 1 ||
            _vm.userParams.gestor >= 1 ||
            (_vm.userParams.tipoUsuario == 1 || _vm.userParams.admin >= 1))
        )?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.loadCadastros.apply(null, arguments)}}},[(_vm.userParams && _vm.userParams.id)?_c('UserParamsForm'):_vm._e()],1):_vm._e()],1):_vm._e()],1),_c('hr')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }