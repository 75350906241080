<template>
  <div class="contracts-panel">
    <PageTitle icon="fa fa-cogs" main="Administração de Contratos" sub="Contratos, parcelas & Cia" />
    <div class="contracts-panel-tabs">
      <b-card no-body id="contracts-panel">
        <b-tabs card>
          <b-tab title="Contratos">
            <ContratosGrid ref="ContratosGrid" />
          </b-tab>
          <b-tab title="Validações BB" v-if="userParams.admin || userParams.consignatario == '1'">
            <ContrValidations ref="ContrValidations" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "@/env";
import ContratosGrid from "./ContratosGrid";
import ContrValidations from "./ContrValidations";
import PageTitle from "../template/PageTitle";

export default {
  name: "ContrAdminPanels",
  components: { ContratosGrid, ContrValidations, PageTitle },
  data: function () {
    return {
      userParams: {},
    };
  },
  methods: {
    loadUserParams() {
      const url = `${baseApiUrl}/users/${this.user.id}`;
      axios.get(url).then((res) => {
        this.userParams = res.data;
        this.userParams.domainCli = `${this.userParams.cliente}_${this.userParams.dominio}`;
      });
    },
  },
  mounted() {
    this.loadUserParams();
  },
  computed: mapState(["user"]),
};
</script>

<style>
</style>