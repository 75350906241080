import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Importe os arquivos CSS de Bootstrap e BootstrapVue
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueCookies from 'vue-cookies'

import ViaCep from 'vue-viacep'
import money from 'v-money'

import './config/bootstrap'
import './config/msgs'
import './config/axios'
import './config/mq'

import store from './config/store'
import router from './config/router'
import vSelect from 'vue-select'

// Instale BootstrapVue
Vue.use(BootstrapVue);
// Opcionalmente, instale o plugin de ícones BootstrapVue
Vue.use(IconsPlugin);
Vue.component('v-select', vSelect)
Vue.use(ViaCep);
Vue.use(money, {
    decimal: ",",
    thousands: ".",
    prefix: "",
    suffix: "",
    precision: 2,
    masked: false,
});
Vue.config.productionTip = false
Vue.use(VueCookies)

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')