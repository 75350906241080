<template>
  <b-modal v-model="showModal" size="lg" centered hide-backdrop class="modal" title="Aviso Importante: Conformidade com a LGPD">
    <p>Prezado Usuário,</p>
    <p>
      Para garantir a proteção e a privacidade dos dados pessoais dos nossos clientes, implementamos uma atualização
      importante no nosso sistema de consignados. A partir de agora, o deferimento de operações exigirá a autenticação
      com a senha do cliente.
    </p>
    <p>
      Essa mudança é essencial para cumprir a Lei Geral de Proteção de Dados (LGPD), que exige o consentimento
      explícito e a segurança no tratamento de dados pessoais. A LGPD protege os direitos dos nossos clientes,
      garantindo que seus dados sejam utilizados de forma segura e transparente.
    </p>
    <p>
      Contamos com a compreensão e colaboração de todos para assegurar que nossas práticas estejam alinhadas com as
      melhores práticas de segurança e conformidade legal.
    </p>
    <p>
      Agradecemos pelo compromisso com a proteção dos dados e pela confiança em nosso sistema.
    </p>
    <p>Atenciosamente,<br>Mega Assessoria e Tecnologia LTDA</p>
    <p>Fonte e referência: <a href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm" v-b-tooltip.hover title="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm" target="_blank">LGPD</a></p>
    <template #modal-footer="{ ok }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="success" @click="ok">
        Fechar
      </b-button>
      <b-button size="sm" variant="danger" @click="doNotShowAgain()">
        Não mostrar mais
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      if (!this.getCookie("lgpdModalDismiss")) {
        this.showModal = true;
      }
    },
    doNotShowAgain() {
      this.setCookie("lgpdModalDismiss", "true", 3650);
      this.closeModal();
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  },
  mounted() {
    this.openModal();
  }
};
</script>

<style>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
